function measureText(text, font) {
    const span = document.createElement("span");
    span.appendChild(document.createTextNode(text));
    Object.assign(span.style, {
        font: font,
        margin: "0",
        padding: "0",
        border: "0",
        whiteSpace: "nowrap",
        whiteSpaceCollapse: "preserve-spaces",
    });
    document.body.appendChild(span);
    const {width, height} = span.getBoundingClientRect();
    // get ascent and descent
    span.remove();
    return {width, height};
}

export default measureText;