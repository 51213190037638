import React, {useRef, useState} from "react";
import {QRCodeCanvas} from 'qrcode.react';
import "./QRCode.css";
import {measureTextHeight, drawMultilineText} from "../textTools/multilineText";


function downloadStringAsFile(data, filename) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
}


const QRCodeComponent = ({value, hint, size, bg_color, fg_color, download, subtitle, ...props}) => {
  const canvasRef = React.useRef(null);
  const qrcode_canvas = <QRCodeCanvas value={value}
                                      title={hint}
                                      size={size}
                                      bgColor={bg_color}
                                      fgColor={fg_color}
                                      level="L"
                                      ref={canvasRef}
  />

  const text_size = 16;
  const font_name = "Rubik"
  const font = `${text_size}px ${font_name}`;
  let text_height = 0;
  if (subtitle !== null) {
    text_height = measureTextHeight(subtitle, font, 150);
  }


  // on mount
  React.useEffect(() => {
    if (subtitle !== null) {
      // draw text on canvas
      // draw text on canvas
      const canvas = canvasRef.current;
      console.log(canvasRef.current);
      const ctx = canvas.getContext('2d');
      const current_qr_code = canvas.toDataURL();
      // make canvas higher by text_height, but keep the content already drawn
      canvas.height += text_height;
      // draw current_qr_code on canvas
      const img = new Image();
      img.src = current_qr_code;
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        // fill background with background color
        ctx.fillStyle = bg_color;
        ctx.fillRect(0, canvas.height - text_height, canvas.width, text_height);
        // draw text in fg color centered to middle
        ctx.font = font;
        // draw text at the bottom
        // ctx.fillText(text, canvas.width / 2, canvas.height - text_height / 2, canvas.width - 10);
        // set background color to none
        drawMultilineText(ctx, subtitle, canvas.width / 2, canvas.height - text_height, font,
          "center", canvas.width - 10, "top", fg_color);
        // update canvas style
        canvas.style.width = "100%";
        canvas.style.height = "auto";
      }
    }
  }, [canvasRef, bg_color, fg_color, text_height, font, subtitle]);


  function onClick() {
    const data = canvasRef.current.toDataURL();
    downloadStringAsFile(data, 'qrcode.png');
  }

  if (download) {
    return <div className={"qr-container"} style={{width: "128px", height: (128 + text_height).toString() + "px"}}
                onClick={onClick}>
      <div className={"qr-code-div"}>
        {qrcode_canvas}
      </div>
      <div className={"overlay"}>
        <img src={process.env.PUBLIC_URL + "/download_icon.png"} alt="download" className={"download-icon"}/>
      </div>
    </div>
  } else {
    return qrcode_canvas;
  }
}

export default QRCodeComponent;
