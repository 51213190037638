import measureText from "./measureText";
import determineTextDirection from "./determineTextDirection";


function splitSingleLine(text, font, max_width) {
  let lines = [];
  let lineCount = 0;
  let tmpTxt = text.split(" ");
  lines[lineCount] = [];
  for (let t = 0; t < tmpTxt.length; t++) {
    lines[lineCount].push(tmpTxt[t]);
    if (measureText(lines[lineCount].join(" "), font).width > max_width && lines[lineCount].length > 1) {
      let lastItem = lines[lineCount].pop();
      lineCount++;
      lines[lineCount] = [lastItem];
    }
  }
  return lines;
}

function splitLines(text, font, max_width) {
  // split on newlines
  let lines = text.split("\n");
  // split each line on spaces
  let split_lines = [];
  for (let l = 0; l < lines.length; l++) {
    split_lines.push(splitSingleLine(lines[l], font, max_width));
  }
  // flatten the array
  let flat_lines = [];
  for (let l = 0; l < split_lines.length; l++) {
    for (let s = 0; s < split_lines[l].length; s++) {
      flat_lines.push(split_lines[l][s]);
    }
  }
  return flat_lines;
}

function measureTextHeight(text, font, max_width) {
  let lines = splitLines(text, font, max_width);
  return lines.length * measureText(text, font).height;
}

function drawMultilineText(context, text, x, y, font, align, max_width,
                           valign = "top",
                           text_color = "black",
                           outline_color = "none",
                           background_color = "none") {
  let lines = splitLines(text, font, max_width);

  context.font = font;
  context.textAlign = align;
  context.direction = determineTextDirection(text);
  context.miterLimit = 2;
  context.lineJoin = 'circle';

  const ascent = context.measureText(text).fontBoundingBoxAscent;
  const descent = context.measureText(text).fontBoundingBoxDescent;

  if (valign === "bottom") {
    y -= (lines.length - 1) * measureText(text, font).height + descent;
  } else if (valign === "middle") {
    y -= (lines.length - 1) * measureText(text, font).height / 2;
  } else if (valign === "top") {
    y += ascent
  } else {
    console.error("Invalid vertical alignment: " + valign);
  }


  let offset = measureText(text, font).height;
  for (let l = 0; l < lines.length; l++) {
    let line_text = lines[l].join(" ");
    // remove spaces from the beginning and end of line text
    line_text = line_text.replace(/^\s+|\s+$/g, "");

    // draw the background
    if (background_color.toLowerCase() !== "none") {
      // draw a background rectangle
      context.fillStyle = background_color;
      const line_height = measureText(line_text, font).height;
      const line_width = Math.min(measureText(line_text, font).width, max_width);
      const ascent = context.measureText(line_text).fontBoundingBoxAscent;
      const descent = context.measureText(line_text).fontBoundingBoxDescent;
      let rect_x = x;
      if (align === "center") {
        rect_x -= line_width / 2;
      } else if (align === "right") {
        rect_x -= line_width;
      }
      let rect_y = y + l * offset - ascent;
      context.fillRect(rect_x, rect_y, line_width, line_height);
    }
    // draw the outline
    if (outline_color.toLowerCase() !== "none") {
      context.strokeStyle = outline_color;
      context.lineWidth = 7;
      context.strokeText(line_text, x, y + l * offset, max_width);
    }
    // draw the text
    context.fillStyle = text_color;
    context.lineWidth = 1;
    context.fillText(line_text, x, y + l * offset, max_width);
  }
}

export {splitLines, measureTextHeight, drawMultilineText};
