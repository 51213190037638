function determineTextDirection(text) {
    // eslint-disable-next-line no-useless-concat
    const ltrChars = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF'
    const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC'

    const rtlDirCheck = new RegExp('[' + rtlChars + ']');
    const ltrDirCheck = new RegExp('[' + ltrChars + ']');
    const directionalChar = new RegExp('[' + rtlChars + ltrChars + ']');
    // find the first directional character. If there is none, return 'ltr'
    let firstDirChar = text.search(directionalChar);
    if (firstDirChar === -1) {
        return 'ltr';
    }
    // if the first character is RTL, return 'rtl'
    if (rtlDirCheck.test(text[firstDirChar])) {
        return 'rtl';
    }
    // if the first character is LTR, return 'ltr'
    if (ltrDirCheck.test(text[firstDirChar])) {
        return 'ltr';
    }
    // if no direction was found, return 'ltr'
    return 'ltr';
}

export default determineTextDirection;